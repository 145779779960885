.footer {
  display: flex;
  align-items: center;
  background-color: rgb(38,38,38);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-top: 2rem;
  flex-direction: column;
  padding: 2rem 0;
  color: rgb(221, 221, 221);

  a {
    color: rgb(221, 221, 221);
    text-decoration: none;
  }
  a:visited {
    color: rgb(221, 221, 221);
  }
}

.footer-collection {
  margin: 0.6rem 0;
}

.footer-collection-top {
  margin-top: 2.7rem;
}

.footer-link {
  text-decoration: none;
  color: black;
}

.footer-link:visited {
  color: black;
}