// Mobil
.mvi-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto 4rem auto;
  max-width: 1000px;
}
.mvi-content-reverse {
  flex-direction: column-reverse;
  margin-top: 3rem;
}
.mvi-content-normal {
  .mvi-title-desc {
    margin-top: 3rem;
  }
}

.mvi-img-container {
  position: relative;
  cursor: default;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  width: 100%;

  .mvi-img-small {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: gray;
    max-width: 80px;
    border-radius: 4px;
  }

  .mvi-img-small-single {
    max-width: 130px;
  }

  .copyright {
    color: white;
    position: absolute;
    right: 10px;
    top: 10px;
    font-style: italic;
    font-size: 0.6rem;
  }
}

// Mellan grej
@media only screen and (min-width: 588px){
  .mvi-img-container {
    .mvi-img-small {
      max-width: 121px;
    }

    .mvi-img-small-single {
      max-width: 300px;
    }
  }
}

@media only screen and (min-width: 788px){
  .mvi-img-container {
    .mvi-img-small {
      max-width: 200px;
    }

    .mvi-img-small-single {
      max-width: 470px;
    }
  }
}
// Desktop
@media only screen and (min-width: 1035px){
  .mvi-img-container {
    .mvi-img-small {
      max-width: 300px;
    }

    .mvi-img-small-single {
      max-width: 470px;
    }
  }
}
