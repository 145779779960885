.header {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  min-height: 120px;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
}
#header-text {
  font-weight: 400;
  text-align: center;
  font-family: 'Play', sans-serif;
}

.header-subheading {
  display: none;
}

.header-ul-list {
  list-style-type: none;
  line-height: 3;
  font-size: 18px;
  font-weight: 500;
}

.header-link-menu {
  z-index: 10;
  background-color: white;
  position: fixed;
  padding: 0 1rem;
  min-width: 40vw;
  height: 100%;
  top: 7.4rem;
  -webkit-transform: translateX(-2000%);
  transform: translateX(-2000%);

  a {
    border-bottom: 1px solid rgb(144, 144, 144);
    padding-bottom: 5px;
    color: black;
    text-decoration: none;
  }
  a:visited {
    border-bottom: 1px solid rgb(144, 144, 144);
    padding-bottom: 5px;
    color: black;
  }
}

.header-link-desktop {
  display: none;
}

.header-link-desktop2 {
  display: block;
}

.shadow-none {
  display: none;
}

.shadow {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.2;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}

.move {
  -webkit-animation: slide-in-left 1s cubic-bezier(.215, .61, .355, 1) forwards;
  animation: slide-in-left 1s cubic-bezier(.215, .61, .355, 1) forwards;
}
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-2000%);
    transform: translateX(-200%)
  }
  to {
    -webkit-transform: translateX(-30vw);
    transform: translateX(-30vw);
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-2000%);
    transform: translateX(-200%)
  }
  to {
    -webkit-transform: translateX(-30vw);
    transform: translateX(-30vw)
  }
}

.move-back {
  -webkit-animation: slide-out-left 1s cubic-bezier(.215, .61, .355, 1) forwards;
  animation: slide-out-left 1s cubic-bezier(.215, .61, .355, 1) forwards;
}
@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(-30vw);
    transform: translateX(-30vw);
  }
  to {
    -webkit-transform: translateX(-2000%);
    transform: translateX(-200%)
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(-30vw);
    transform: translateX(-30vw)
  }
  to {
    -webkit-transform: translateX(-2000%);
    transform: translateX(-200%)
  }
}

.hamburger {
  display: flex;
  flex-direction: column;
  height: 25px;
  justify-content: space-around;

  .line {
    height: 1px;
    width: 30px;
    background: black;
  }
}

.line-1 {
  -webkit-animation: line-1-animation 0.6s cubic-bezier(.215, .61, .355, 1) forwards;
  animation: line-1-animation 0.6s cubic-bezier(.215, .61, .355, 1) forwards;
}
.line-2 {
  -webkit-animation: line-2-animation 0.2s cubic-bezier(.215, .61, .355, 1) forwards;
  animation: line-2-animation 0.2s cubic-bezier(.215, .61, .355, 1) forwards;
}
.line-3 {
  -webkit-animation: line-3-animation 0.6s cubic-bezier(.215, .61, .355, 1) forwards;
  animation: line-3-animation 0.6s cubic-bezier(.215, .61, .355, 1) forwards;
}
@-webkit-keyframes line-1-animation {
  0% {
    -webkit-transform: rotate(0) translateY(0);
    transform: rotate(0) translateY(0);
  }
  to {
    -webkit-transform: rotate(-45deg) translateY(6px) translateX(-7px);
    transform: rotate(-45deg) translateY(6px) translateX(-7px);
  }
}
@keyframes line-1-animation {
  0% {
    -webkit-transform: rotate(0) translateY(0);
    transform: rotate(0) translateY(0);
  }
  to {
    -webkit-transform: rotate(-45deg) translateY(6px) translateX(-7px);
    transform: rotate(-45deg) translateY(6px) translateX(-7px);
  }
}
@-webkit-keyframes line-2-animation {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes line-2-animation {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes line-3-animation {
  0% {
    -webkit-transform: rotate(0) translateY(0);
    transform: rotate(0) translateY(0);
  }
  to {
    -webkit-transform: rotate(45deg) translateY(-6px) translateX(-6px);
    transform: rotate(45deg) translateY(-6px) translateX(-6px);
  }
}
@keyframes line-3-animation {
  0% {
    -webkit-transform: rotate(0) translateY(0);
    transform: rotate(0) translateY(0);
  }
  to {
    -webkit-transform: rotate(45deg) translateY(-6px) translateX(-6px);
    transform: rotate(45deg) translateY(-6px) translateX(-6px);
  }
}

.line-1-remove {
  -webkit-animation: line-1-goDefault 1s cubic-bezier(.215, .61, .355, 1) forwards;
  animation: line-1-goDefault 1s cubic-bezier(.215, .61, .355, 1) forwards;
}
.line-2-remove {
  -webkit-animation: line-2-goDefault 1s cubic-bezier(.215, .61, .355, 1) forwards;
  animation: line-2-goDefault 1s cubic-bezier(.215, .61, .355, 1) forwards;
}
.line-3-remove {
  -webkit-animation: line-3-goDefault 1s cubic-bezier(.215, .61, .355, 1) forwards;
  animation: line-3-goDefault 1s cubic-bezier(.215, .61, .355, 1) forwards;
}
@-webkit-keyframes line-1-goDefault {
  0% {
    -webkit-transform: rotate(-45deg) translateY(6px) translateX(-7px);
    transform: rotate(-45deg) translateY(6px) translateX(-7px);
  }
  to {
    -webkit-transform: rotate(0) translateY(0);
    transform: rotate(0) translateY(0);
  }
}
@keyframes line-1-goDefault {
  0% {
    -webkit-transform: rotate(-45deg) translateY(6px) translateX(-7px);
    transform: rotate(-45deg) translateY(6px) translateX(-7px);
  }
  to {
    -webkit-transform: rotate(0) translateY(0);
    transform: rotate(0) translateY(0);
  }
}
@-webkit-keyframes line-2-goDefault {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes line-2-goDefault {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes line-3-goDefault {
  0% {
    -webkit-transform: rotate(45deg) translateY(-6px) translateX(-6px);
    transform: rotate(45deg) translateY(-6px) translateX(-6px);
  }
  to {
    -webkit-transform: rotate(0) translateY(0);
    transform: rotate(0) translateY(0);
  }
}
@keyframes line-3-goDefault {
  0% {
    -webkit-transform: rotate(45deg) translateY(-6px) translateX(-6px);
    transform: rotate(45deg) translateY(-6px) translateX(-6px);
  }
  to {
    -webkit-transform: rotate(0) translateY(0);
    transform: rotate(0) translateY(0);
  }
}

// BREAKPOINTS
// Minor breakpoint
@media only screen and (min-width: 533px){
  #header-text {
    letter-spacing: 0.3rem;
  }
  .header-subheading {
    display: block;
    position: absolute;
    top: 69px;
    font-weight: 300;
    font-size: 0.8em;
    letter-spacing: -0.6px;
  }
}

// Desktop
@media only screen and (min-width: 935px){
  .header {
    position: static;
  }
  .header-subheading {
    display: block;
    position: absolute;
    top: 49px;
    font-weight: 300;
    font-size: 0.8em;
    letter-spacing: -0.6px;
  }

  .linkitems-mover {
    display: none;
  }
  .header-link-menu {
    position: static;
    height: auto;
    width: auto;
    -webkit-transform: translateX(0);
    transform: translateX(0);

    a {
      border-bottom: none;
      padding-bottom: 0;
    }
    a:visited {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  .header-ul-list {
    display: none;
  }

  .header-link-desktop {
    display: inline-block;
    position: relative;
  }
  
  .header-link-desktop2 {
    display: inline-block;
    position: relative;  
  }

  .smooth-transition-header-line {
    background: #405cf5;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -5px;
    
    -webkit-animation: abrakadabra2 0.4s cubic-bezier(.215, .61, .355, 1) forwards;
    animation: abrakadabra2 0.4s cubic-bezier(.215, .61, .355, 1) forwards;
  }
  .activeTab-line-hover {
    background: #405cf5;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -5px;
    -webkit-animation: abrakadabra 0.4s cubic-bezier(.215, .61, .355, 1) forwards;
    animation: abrakadabra 0.4s cubic-bezier(.215, .61, .355, 1) forwards;
  }

  @keyframes abrakadabra {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes abrakadabra2 {
    0% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .activeTab-line {
    animation: none;
    background: #405cf5;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -5px;
  }
  .activeTab-line2 {
    left: -6px;
    background: #405cf5;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 10px;
  }


  .hamburger {
    display: none;
  }
  .move {
    -webkit-animation: none;
    animation: none;
  }
  .move-back {
    -webkit-animation: none;
    animation: none;
  }
}