.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
}

.btn:disabled {
  cursor: default;
}

.btn:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

.cursor-default {
  cursor: default;
}

.regular-paragraph {
  font-weight: 300;
  line-height: 1.7;
}

.card {
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  margin: 12px 0 0;
}

.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}

/* Desktop */
@media only screen and (min-width: 935px){
  .regular-paragraph {
    font-weight: 400;
  }
}