.inputlabel {
  letter-spacing: 0.5px;
  margin: 0.5rem 0 0.3rem 0;
}

.inputinput {
  outline-style: none;
	box-shadow: none;
	border-color: transparent; 
  
  height: 31px;
  border: 0.5px solid black;
  border-radius: 2px;
  padding-left: 7px;
}

.inputinput:focus {
  border: 0.8px solid #405cf5;
}