.form-container {
  background: #fff;
  border-radius: 5px;
  padding: 1rem 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.formArea {
  width: 100%;
}

.hire-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px 0 0 5px;
}

.hire-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5rem 1rem 0 1.5rem;
  font-size: 24px;
  line-height: 1.5;
  color: rgb(255, 250, 239);
  font-weight: 500;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  .error-text{
    margin-top: 1px;
    font-size: 11px;
    font-weight: 600;
    color: orangered;
  }
  .custom-textarea {
    height: auto;
    padding: 10px 7px;
    height: 135px;
    overflow-y: scroll;
    font-size: 14px;
  }
}

.contact-minor-container {
  display: flex;
  align-items: center;
  
  .btn {
    min-width: 105px;
  }
}

.mail-contact-info {
  margin-top: 20px;
  font-size: 12px;
  margin-left: 5px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loadingImg {
  animation: spin 1.5s infinite;
  margin: 12px 0 0 12px;
  width: 30px;
}
.contact-submitstatus {
  font-size: 10px;
  margin: 12px 0 0 12px;
  font-weight: 600;
}

#message {
  resize: vertical;
}

.contentArea {
  display: none;
}

@-webkit-keyframes loading-bigger {
  0% {
    height: 9px;
  }
  50% {
    height: 25px;
  }
  100% {
    height: 9px;
  }
}
@keyframes loading-bigger {
  0% {
    height: 9px;
  }
  50% {
    height: 25px;
  }
  100% {
    height: 9px;
  }
}
.loadingContiner {
  display: flex;
  align-items: center;
  margin: 12px 0 0 12px;

  .loading-line {
    margin: 0 2px;
    background: lightblue;
    height: 9px;
    width: 6px;
    border-radius: 9999px;
  }
}

// Desktop
@media only screen and (min-width: 935px){
  .form-container {
    margin: 2.5rem auto;
    display: flex;
    padding: 0;
    position: relative;
    max-width: 1000px;
  }

  .contentArea {
    display: block;
    position: absolute;
    border-radius: 5px 0 0 5px;
    top: 0;
    left: 0;
    background-color: rgb(224, 224, 224);
    height: 100%;
    width: 40%;
  }

  .formArea {
    padding: 1rem 2rem;
    padding-left: 45%;
    width: 50%;
  }
}