// Mobil
.hero-content {
  margin-bottom: 4rem;
}

.hero-content-reverse {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
.hero-content-normal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-img-container {
  position: relative;
  cursor: default;
  z-index: 1;
  margin-top: 10px;

  .hero-img-small {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: gray;
    min-height: 200px;
    min-width: 200px;
    border-radius: 4px;
  }
  .copyright {
    color: white;
    position: absolute;
    right: 10px;
    top: 10px;
    font-style: italic;
    font-size: 0.6rem;
  }
}

// Desktop
@media only screen and (min-width: 935px){
  .hero-content {
    margin-top: 4rem;
    display: flex;
  }

  .hero-content-reverse {
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  .hero-content-normal {
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row;
  }

  .hero-title-desc{
    padding-right: 2rem;

    #hero-title {
      margin-top: 0;
    }
  }

  .hero-img-container {
    margin-top: 0;
  }

  .hero-img-small {
    min-height: 330px;
    min-width: 400px;
  }
  
  .hero-title-desc-reverse {
    margin-left: 4rem;
    padding: 0;

    #hero-title {
      margin-top: 0;
    }
  }
}
