.pilot-big-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1444px;
}

.pilot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-circle {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.profile-circle-image {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  object-fit: cover;
}

.pilot-name {
  font-size: 18px;
  font-weight: 500;
}

.pilot-name-desc {
  font-size: 12px;
}

.about-h1 {
  margin-top: 4rem;
  margin-left: 10px;
}