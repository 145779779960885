// Mobil
.main-content {
  width: 78%;
  margin: 0 auto;
  margin-top: 10rem;
}


// Desktop
@media only screen and (min-width: 935px){
  .main-content {
    width: 88%;
    margin: 0 auto;
    max-width: 1444px;
  }
  
}